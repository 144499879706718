<template>
  <div class="service-tags" v-loading="loading">
    <p class="eof" v-if="!loading && data.length === 0">暂无数据</p>
    <el-tag
      v-for="tag in data"
      :key="tag.id"
      :class="`${isSelectedTag(tag.tagName) ? 'is-active' : ''}`"
      :type="`${isSelectedTag(tag.tagName) ? '' : 'info'}`"
      :closable="isSelectedTag(tag.tagName)"
      @click="handleClick(tag)"
      @close="handleRemove(tag)"
      >{{ tag.tagName }}({{ tag.tagCount }})</el-tag
    >
    <a class="clear" @click="handleRemove(0)" v-if="selectedTags.length > 0">清空</a>
  </div>
</template>
<script>
import { ref, watch } from 'vue';
import { getTagsWithCount } from '@/api/cim';
export default {
  name: 'ServiceTags',
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    begin: {
      type: Boolean,
      default: () => true,
    },
  },
  setup(props, { emit }) {
    const data = ref([]);
    const loading = ref(true);
    const selectedTags = ref([...props.value]);
    watch(
      () => props.value,
      () => {
        selectedTags.value = [...props.value];
      },
      {
        deep: true,
      },
    );
    const fetchTree = async () => {
      loading.value = true;
      try {
        const { data: res } = await getTagsWithCount();
        data.value = res;
      } catch (e) {
        console.log(e);
      }
      loading.value = false;
    };
    watch(
      () => props.begin,
      (nn) => {
        if (nn) {
          fetchTree();
        }
      },
    );
    if (props.begin) {
      fetchTree();
    }

    const isSelectedTag = (tag) => selectedTags.value.includes(tag);
    const handleClick = (tag) => {
      if (!isSelectedTag(tag.tagName)) {
        selectedTags.value.push(tag.tagName);
        emit('change', selectedTags.value);
      }
    };
    const handleRemove = (tag) => {
      if (tag === 0) {
        selectedTags.value = [];
        emit('change', []);
        return;
      }
      const index = selectedTags.value.indexOf(tag.tagName);
      if (index > -1) {
        selectedTags.value.splice(index, 1);
        emit('change', selectedTags.value);
      }
    };

    return {
      loading,
      data,
      selectedTags,
      isSelectedTag,
      handleClick,
      handleRemove,
    };
  },
};
</script>
<style lang="scss" scoped>
.service-tags {
  margin-top: -10px;
  min-height: 120px;
  .clear {
    color: #0009;
    display: inline-block;
    margin-top: 10px;
    &:hover {
      color: #006eff;
    }
  }
}
:deep(.el-tag) {
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer;
  &.is-active {
    color: #006eff;
  }
}
.eof {
  padding: 30px 0;
  color: var(--el-text-color-secondary);
  font-size: var(--el-font-size-base);
  text-align: center;
}
</style>
