<template>
  <ul class="src-list--grid" :class="`src-list--${theme}`">
    <li class="item conic" v-for="item in list" :key="item.id" @click="handleCheckDetail(item.id)">
      <div class="item__body">
        <figure class="item__thumb">
          <!-- <div class="item__thumb_auth">
            <i class="icon el-icon-lock"></i>
            <span>需要申请权限</span>
          </div> -->
          <div class="item__thumb_img">
            <img :src="item.serviceThum" alt="" v-if="item.serviceThum" class="kenburns-top" />
            <img :src="NoImage" class="no-img" alt="" v-else />
          </div>
        </figure>
        <h3 class="item__title" :title="item.serviceName">
          {{ item.serviceName }}
        </h3>
        <p class="item__info">
          <svg-icon class="icon" icon-name="calendar" />
          <span>{{ item.createTime }}</span>
        </p>
        <p class="item__info">
          <i class="icon el-icon-view"></i><span>{{ item.serviceViews }}</span>
        </p>
        <div class="item__foot">
          <span
            ><i class="icon el-icon-user"></i><span>{{ item.serviceAuthor }}</span></span
          >
          <span class="icon-label action">
            <span>进入详情</span>
            <i class="icon el-icon-right"></i>
          </span>
        </div>
      </div>
    </li>
  </ul>
</template>
<script>
import NoImage from '@/assets/img/no-img2.svg';
import { useRouter } from 'vue-router';
export default {
  name: 'GridList',
  components: {},
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    theme: {
      type: String,
      default: 'normal',
    },
  },
  setup() {
    const router = useRouter();
    // 查看当前服务详情
    const handleCheckDetail = (id) => {
      const newPath = router.resolve(`/service-resource-center/${id}`);
      window.open(newPath.href);
    };
    return {
      NoImage,
      handleCheckDetail,
    };
  },
};
</script>
<style lang="scss" scoped>
.src-list--grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, 25%);
  margin: 0 -15px -15px -15px;
  padding: 0;
  list-style: none;
  font-size: 0;
  .item {
    padding: 3px;
    .icon {
      font-size: 16px;
      + span {
        margin-left: 5px;
      }
    }
    &__body {
      background-color: white;
      padding: 10px;
      display: flex;
      flex-flow: column;
      font-size: 12px;
      &:hover {
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
      }
    }
    &__thumb {
      width: 100%;
      flex-shrink: 0;
      overflow: hidden;
      margin: 0;
      padding: 60% 0 0 0;
      background-color: #eee;
      position: relative;
      &_auth,
      &_img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          max-width: 100%;
          &.no-img {
            max-width: 40%;
          }
        }
      }
      &_auth {
        background-color: rgba(0, 0, 0, 0.7);
        color: white;
      }
    }
    &__title {
      font-size: 16px;
      font-weight: bold;
      margin: 10px 0 0 0;
      line-height: 24px;
      padding: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &__info {
      margin: 10px 0 0 0;
      color: #0009;
      display: flex;
      align-items: center;
    }
    &__foot {
      margin: 10px -10px 0 -10px;
      border-top: 1px solid #ebeef5;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 10px 0 10px;
      color: #0009;
      .action {
        color: #006eff;
        .icon {
          transition: all 0.2s;
          margin-left: 5px;
        }
        &:hover {
          .icon {
            transform: translateX(5px);
          }
        }
      }
    }
  }

  &.src-list--dark {
    margin-top: -15px;
    padding: 5px 10px;
    .item {
      &__body {
        transition: all 0.2s ease-in-out;
        background: linear-gradient(135deg, #002b52 0%, #101217 80%);
        //border-radius: 5px;
        .icon {
          color: #5f759b;
        }
        &:hover {
          box-shadow: 0 0 10px rgba($color: #237bfb, $alpha: 0.7);
        }
      }
      &__thumb {
        background: transparent;
      }
      &__title {
        color: #979da6;
      }
      &__info {
        color: #5f759b;
      }
      &__foot {
        color: #5f759b;
        border-top: 1px solid #39455d;
      }
    }
  }

  @keyframes rotate {
    100% {
      transform: rotate(1turn);
    }
  }
  .item {
    margin-bottom: 5px;
  }
  .item:hover {
    position: relative;
    z-index: 0;
    overflow: hidden;
    margin: 5px;

    .kenburns-top {
      -webkit-animation: kenburns-top 4s ease-out both;
      animation: kenburns-top 4s ease-out both;
    }

    &::before {
      content: '';
      position: absolute;
      z-index: -2;
      left: -50%;
      top: -50%;
      width: 200%;
      height: 200%;
      background-color: #1a232a;
      background-repeat: no-repeat;
      background-position: 0 0;
      background-image: conic-gradient(transparent, rgba(168, 239, 255, 1), transparent 30%);
      animation: rotate 1.5s linear infinite;
    }

    &::after {
      content: '';
      position: absolute;
      z-index: -1;
      left: 6px;
      top: 6px;
      width: calc(100% - 12px);
      height: calc(100% - 12px);
      background: #000;
      //border-radius: 5px;
    }
  }

  @keyframes opacityChange {
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes kenburns-top {
    0% {
      -webkit-transform: scale(1) translateY(0);
      transform: scale(1) translateY(0);
      -webkit-transform-origin: 50% 16%;
      transform-origin: 50% 16%;
    }
    100% {
      -webkit-transform: scale(1.25) translateY(-15px);
      transform: scale(1.25) translateY(-15px);
      -webkit-transform-origin: top;
      transform-origin: top;
    }
  }
  @keyframes kenburns-top {
    0% {
      -webkit-transform: scale(1) translateY(0);
      transform: scale(1) translateY(0);
      -webkit-transform-origin: 50% 16%;
      transform-origin: 50% 16%;
    }
    100% {
      -webkit-transform: scale(1.25) translateY(-15px);
      transform: scale(1.25) translateY(-15px);
      -webkit-transform-origin: top;
      transform-origin: top;
    }
  }
}
@media screen and (min-width: 720px) {
  .src-list--grid {
    grid-template-columns: repeat(auto-fill, 50%);
  }
}
@media screen and (min-width: 1080px) {
  .src-list--grid {
    grid-template-columns: repeat(auto-fill, 33.33%);
  }
}
@media screen and (min-width: 1600px) {
  .src-list--grid {
    grid-template-columns: repeat(auto-fill, 25%);
  }
}
@media screen and (min-width: 1980px) {
  .src-list--grid {
    grid-template-columns: repeat(auto-fill, 16.66%);
  }
}
@media screen and (min-width: 3000px) {
  .src-list--grid {
    grid-template-columns: repeat(auto-fill, 12.5%);
  }
}
</style>
