<template>
  <ul class="src-list--normal" :class="`src-list--${theme}`">
    <li class="item" v-for="item in list" :key="item.id" @click="handleCheckDetail(item.id)">
      <figure class="item__thumb">
        <!-- <div class="item__thumb_auth">
            <i class="icon el-icon-lock"></i>
            <span>需要申请权限</span>
          </div> -->
        <div class="item__thumb_img">
          <img :src="item.serviceThum" alt="" v-if="item.serviceThum" class="kenburns-top" />
          <img :src="NoImage" class="no-img" alt="" v-else />
        </div>
      </figure>
      <div class="item__content">
        <div class="item__title" :title="item.serviceName">
          <span>{{ item.serviceName }}</span>
          <span class="item__title-action icon-label">
            <span>进入详情</span>
            <i class="icon el-icon-right"></i>
          </span>
        </div>
        <div class="item__info">
          <div class="icon-label">
            <svg-icon class="icon" icon-name="calendar" /> <span>{{ item.createTime }}</span>
          </div>
          <div class="tags">
            <el-tag type="info" v-for="tag in item.tags" :key="tag">{{ tag }}</el-tag>
          </div>
        </div>
        <div class="item__info">
          <div class="icon-label">
            <i class="icon el-icon-view"></i><span>{{ item.serviceViews }}</span>
          </div>
        </div>
        <div class="item__info">
          <div class="icon-label">
            <i class="icon el-icon-user"></i><span>{{ item.serviceAuthor }}</span>
          </div>
        </div>
      </div>
    </li>
  </ul>
</template>
<script>
import NoImage from '@/assets/img/no-img2.svg';
import { useRouter } from 'vue-router';
export default {
  name: 'NormalList',
  components: {},
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    theme: {
      type: String,
      default: 'normal',
    },
  },
  setup() {
    const router = useRouter();
    // 查看当前服务详情
    const handleCheckDetail = (id) => {
      const newPath = router.resolve(`/service-resource-center/${id}`);
      window.open(newPath.href);
    };
    return {
      NoImage,
      handleCheckDetail,
    };
  },
};
</script>
<style lang="scss" scoped>
.src-list--normal {
  margin: 0;
  padding: 0;
  list-style: none;
  .item {
    padding: 20px;
    background-color: white;
    display: flex;
    flex-flow: row nowrap;
    + .item {
      margin-top: 20px;
    }
    &:hover {
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
    }
    .icon {
      font-size: 16px;
      + span {
        margin-left: 5px;
      }
    }
    :deep(.el-tag) {
      + .el-tag {
        margin-left: 5px;
      }
    }

    &__thumb {
      width: 200px;
      flex-shrink: 0;
      overflow: hidden;
      margin: 0;
      background-color: #eee;
      position: relative;
      &_auth,
      &_img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &_auth {
        background-color: rgba(0, 0, 0, 0.7);
        color: white;
      }
      img {
        max-width: 100%;
        &.no-img {
          max-width: 60%;
        }
      }
    }
    &__content {
      flex: 1;
      margin-left: 20px;
    }

    &__title {
      font-size: 16px;
      font-weight: bold;
      margin: 0;
      padding: 0;
      line-height: 40px;
      border-bottom: 1px solid #ebeef5;
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        display: block;
        max-width: 40vw;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1;
      }
      &-action {
        font-size: 12px;
        font-weight: normal;
        flex-shrink: 0;
        color: #006eff;
        .icon {
          transition: all 0.2s;
          margin-left: 5px;
        }
        &:hover {
          .icon {
            transform: translateX(5px);
          }
        }
      }
    }

    &__info {
      margin: 10px 0 0 0;
      color: #0009;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  &.src-list--dark {
    .item {
      transition: all 0.2s ease-in-out;
      background: linear-gradient(135deg, #002b52 0%, #101217 80%);
      .icon {
        color: #5f759b;
      }
      &:hover {
        box-shadow: 0 0 10px rgba($color: #237bfb, $alpha: 0.7);
      }
      &__thumb {
        background: transparent;
      }
      &__title {
        color: #979da6;
        border-bottom: 1px solid #39455d;
      }
      &__info {
        color: #5f759b;
      }
    }
  }

  .item {
    position: relative;
    margin: 10px;
    overflow: hidden;
    &::after {
      content: '';
      position: absolute;
      top: 2px;
      bottom: 2px;
      right: 2px;
      left: 2px;
      line-height: 92px;
      font-size: 24px;
      border: 2px solid transparent;
      cursor: pointer;
      background: linear-gradient(135deg, #002b52 0%, #101217 80%);
      z-index: -10;
      border-radius: 0;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: -20px;
      left: 0;
      background-color: #a8efff;
      opacity: 0.8;
      transform: rotateZ(-90deg) translate(-100%, -100%);
      transform-origin: top left;
      transition: transform 0.5s;
      transition-timing-function: linear;
      z-index: -10;
      border-radius: 0;
    }
  }
  .item:hover {
    filter: contrast(1.2);

    .kenburns-top {
      -webkit-animation: kenburns-top 4s ease-out both;
      animation: kenburns-top 4s ease-out both;
    }
    &::before {
      transform: rotateZ(0deg) translate(0%, -0%);
    }
  }

  @keyframes opacityChange {
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes kenburns-top {
    0% {
      -webkit-transform: scale(1) translateY(0);
      transform: scale(1) translateY(0);
      -webkit-transform-origin: 50% 16%;
      transform-origin: 50% 16%;
    }
    100% {
      -webkit-transform: scale(1.25) translateY(-15px);
      transform: scale(1.25) translateY(-15px);
      -webkit-transform-origin: top;
      transform-origin: top;
    }
  }
  @keyframes kenburns-top {
    0% {
      -webkit-transform: scale(1) translateY(0);
      transform: scale(1) translateY(0);
      -webkit-transform-origin: 50% 16%;
      transform-origin: 50% 16%;
    }
    100% {
      -webkit-transform: scale(1.25) translateY(-15px);
      transform: scale(1.25) translateY(-15px);
      -webkit-transform-origin: top;
      transform-origin: top;
    }
  }
  @keyframes rotate {
    100% {
      transform: rotate(1turn);
    }
  }
}
</style>
