<template>
  <div class="src">
    <header class="src-header">
      <div class="src-logo">
        <img :src="LogoImg" alt="" width="190" />
        <!-- <el-divider direction="vertical"></el-divider>
        <img :src="LogoTxt" alt="" width="92" class="src-logo__txt" /> -->
      </div>
      <ul class="src-nav" v-show="false">
        <li>
          <router-link to="/service-resource-center" class="skew-link skew-link--lr is-active"
            ><span>服务资源中心</span></router-link
          >
        </li>
        <li class="dropdown">
          <a class="skew-link"><span>开发中心</span></a>
          <ul class="dropdown-menu">
            <li>
              <a href="/freedo/#/admin/developmentCenter/HR" target="_blank"> 高渲染开发文档 </a>
            </li>
            <li>
              <a href="/freedo/#/admin/developmentCenter/WebGL" target="_blank"> WebGL开发文档 </a>
            </li>
          </ul>
        </li>
        <li>
          <router-link to="/service-monitor-center" class="skew-link skew-link--rr"
            ><span>服务监控中心</span></router-link
          >
        </li>
      </ul>
      <div class="src-user">
        <span class="src-user__avatar dropdown">
          <i class="el-icon-user user-nav__icon"></i>
          <ul class="dropdown-menu user-center">
            <li>
              <a href="/service-resource-center/user-center/user-info" target="_blank"> 用户信息 </a>
            </li>
            <li>
              <a href="/service-resource-center/user-center/my-token" target="_blank"> 我的Token </a>
            </li>
            <li>
              <a href="/service-resource-center/user-center/service-apply" target="_blank"> 我的申请 </a>
            </li>
          </ul>
        </span>
        <span class="src-user__nick">
          你好，<br />
          <strong>{{ userInfo?.displayName }}</strong>
        </span>
        <a class="skew-link skew-link--r src-user__logout" @click="handleLogout">
          <span><i class="el-icon-switch-button user-nav__icon"></i></span>
        </a>
      </div>
    </header>
    <aside class="src-banner">
      <video
        data-v-3f45a8a4=""
        x5-video-player-type="h5-page"
        muted="muted"
        width="100%"
        autoplay="autoplay"
        :src="movPath"
        loop="loop"
      ></video>
      <div class="src-banner__body">
        <div class="search-wrap">
          <i class="el-icon-search icon"></i>
          <input
            type="text"
            class="search-input"
            placeholder="请输入服务名"
            v-model.lazy="query.keyword"
            @keyup.enter="fetchList"
            @change="fetchList"
          />
        </div>
        <div class="btn-wrap">
          <a href="/services-center/space" class="skew-link skew-link--lr"><span>创建空间服务</span></a>
          <a href="/services-center/register" class="skew-link skew-link--rr"><span>注册外部空间服务</span></a>
        </div>
      </div>
    </aside>
    <main class="src-main">
      <div class="src-main__list">
        <list-wrap
          backgroundColor="transparent"
          :loading="loading"
          :empty="list.length === 0"
          :handleCreate="null"
          :hasCreateAuth="false"
          :use-select-auth="false"
        >
          <div class="src-main__list_filter">
            <span class="list-count">总共{{ total }}个服务资源</span>
            <div class="filter-r">
              <el-button type="text" style="margin-right: 20px" @click="openTable2Excel">导出Excel</el-button>
              <!--              行政区划-->
              <div style="opacity: 0.9">
                <el-select style="width: 185px" v-model="areaList.provence" placeholder="请选择省/直辖市">
                  <el-option
                    v-for="area in resolveCodes.provence"
                    :key="area.id"
                    :label="area.areaName"
                    :value="area.areaCode"
                  ></el-option> </el-select
                >&nbsp;&nbsp;
                <el-select
                  style="width: 185px"
                  v-model="areaList.city"
                  @change="changeCity"
                  placeholder="请选择市级区域"
                >
                  <el-option
                    v-for="area in resolveCodes.city"
                    :key="area.id"
                    :label="area.areaName"
                    :value="area.areaCode"
                  ></el-option> </el-select
                >&nbsp;&nbsp;
                <el-select
                  style="width: 185px"
                  v-model="areaList.area"
                  @change="handleAreaSortSelect"
                  clearable
                  placeholder="请选择区县级区域"
                >
                  <el-option
                    v-for="area in resolveCodes.area"
                    :key="area.id"
                    :label="area.areaName"
                    :value="area.areaCode"
                  ></el-option> </el-select
                >&nbsp;&nbsp;&nbsp;&nbsp;
              </div>
              <!--              行政区划-->
              <div style="display: none" class="list-sort dropdown list-areas">
                <div class="skew-link skew-link--r dropdown-toggle">
                  <span>
                    <span>成都</span>
                    <span>{{ hoverAreaName ? `: ${hoverAreaName}` : '' }}</span>
                    <i class="el-icon-arrow-up"></i>
                    <i class="el-icon-arrow-down"></i>
                  </span>
                </div>
                <ul class="dropdown-menu">
                  <li @click="handleAreaSort('')" @mouseleave="handleHoverArea('')" @mouseover="handleHoverArea('')">
                    <a>
                      <span>成都</span>
                    </a>
                  </li>
                  <li
                    v-for="area in areasCodes"
                    :key="area.areaCode"
                    @click="handleAreaSort(area.areaCode, area.areaName)"
                  >
                    <a>
                      <span @mouseup="handleHoverArea(area.areaName)">{{ area.areaName }}</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="list-types">
                <a
                  class="skew-link skew-link--lr"
                  :class="`${listType === 'grid' ? 'is-active' : ''}`"
                  @click="handleChangeListType('grid')"
                >
                  <span> <svg-icon icon-name="grid" /></span
                ></a>
                <a
                  class="skew-link skew-link--rr"
                  :class="`${listType === 'list' ? 'is-active' : ''}`"
                  @click="handleChangeListType('list')"
                >
                  <span>
                    <svg-icon icon-name="list2" />
                  </span>
                </a>
              </div>
              <div class="list-sort dropdown">
                <div class="skew-link skew-link--r dropdown-toggle">
                  <span>
                    <span>{{ sortInfo }}</span>
                    <i class="el-icon-sort-up" v-if="query.sortType === 1"></i>
                    <i class="el-icon-sort-down" v-else></i>
                  </span>
                </div>
                <ul class="dropdown-menu">
                  <li @click="handleSort(1, 1)">
                    <a>
                      <span>创建时间升序</span>
                      <i class="el-icon-sort-up"></i>
                    </a>
                  </li>
                  <li @click="handleSort(1, 0)">
                    <a>
                      <span>创建时间降序</span>
                      <i class="el-icon-sort-down"></i>
                    </a>
                  </li>
                  <li @click="handleSort(2, 1)">
                    <a>
                      <span>更新时间升序</span>
                      <i class="el-icon-sort-up"></i>
                    </a>
                  </li>
                  <li @click="handleSort(2, 0)">
                    <a>
                      <span>更新时间降序</span>
                      <i class="el-icon-sort-down"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <grid-list :list="list" v-if="listType === 'grid'" theme="dark" />
          <normal-list :list="list" v-if="listType === 'list'" theme="dark" />
          <div class="list-page" v-if="total > 0 && !loading">
            <el-pagination
              background
              :page-size="query.pageSize"
              :current-page="query.pageNo"
              layout="prev, pager, next"
              :total="total"
              @size-change="handlePageSizeChange"
              @current-change="handlePageChange"
            />
          </div>
        </list-wrap>
      </div>
      <aside class="src-main__aside">
        <el-collapse v-model="collapse">
          <el-collapse-item name="type">
            <template #title>
              <i class="el-icon-folder-opened"></i>
              服务类型
              <el-button
                type="text"
                @click.prevent.stop="handleQueryChange('catalogId', '')"
                style="margin-left: 20px; font-size: 14px"
                >全部</el-button
              >
            </template>
            <div>
              <service-type-tree
                :value="query.catalogId"
                @change="(v) => handleQueryChange('catalogId', v)"
                :begin="initBegin"
              />
            </div>
          </el-collapse-item>
          <el-collapse-item name="tag">
            <template #title>
              <i class="el-icon-price-tag"></i>
              标签
            </template>
            <div>
              <service-tags
                v-model="query.tagNames"
                @change="(v) => handleQueryChange('tagNames', v)"
                :begin="initBegin"
              />
            </div>
          </el-collapse-item>
        </el-collapse>
      </aside>
    </main>
    <download-dialog ref="downloadDialogRef"></download-dialog>
  </div>
</template>
<script>
import LogoImg from '@/assets/img/logo-src.png';
import LogoTxt from '@/assets/img/logo-w-text.png';
import { userInfo } from '@/layout/messageCenter/user-info';
import { computed, reactive, ref, watch } from 'vue';
import { getAreaCodes, getNextAreaCode, getRootAreaCode, getSpaceListWithPic, getToken2Session } from '@/api/cim';
import NoImage from '@/assets/img/no-img2.svg';
import GridList from './list/Grid.vue';
import NormalList from './list/List.vue';
import ServiceTypeTree from './ServiceTypeTree.vue';
import ServiceTags from './ServiceTags.vue';
import { logout } from '@/api/auth';
import { getUser } from '@/shared/userinfo';
import DownloadDialog from './DownloadDialog.vue';

export default {
  components: {
    GridList,
    NormalList,
    ServiceTypeTree,
    ServiceTags,
    DownloadDialog,
  },
  setup() {
    const movPath =
      process.env.NODE_ENV === 'development' ? 'https://cdn.welink.qq.com/citybase/banner-video.mp4' : '/cityBase.mov';

    // eslint-disable-next-line
    // const getQuerys = (token) => {
    //   const query = window.location.search.substring(1);
    //   const vars = query.split('&');
    //   // eslint-disable-next-line @typescript-eslint/prefer-for-of
    //   for (let i = 0; i < vars.length; i++) {
    //     const pair = vars[i].split('=');
    //     // eslint-disable-next-line
    //     if (pair[0] === token) {
    //       return pair[1];
    //     }
    //   }
    //   return false;
    // };

    const debounce = (fn, delay) => {
      let timeout;
      return function () {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          // eslint-disable-next-line prefer-rest-params
          fn.apply(this, arguments);
        }, delay);
      };
    };

    const token = new URL(window.location.href).searchParams.get('token');

    const getPageSizeByScreen = () => {
      const width = document.body.scrollWidth;
      if (width >= 1600) {
        return 24;
      }
      if (width >= 1080) {
        return 21;
      }
      return 24;
    };

    getPageSizeByScreen();

    const listType = ref('grid');
    const collapse = ref(['type', 'tag']);
    const handleChangeListType = (type) => (listType.value = type);
    const query = reactive({
      keyword: '',
      catalogId: null,
      tagNames: [],
      pageNo: 1,
      pageSize: getPageSizeByScreen(),
      sortField: 1,
      sortType: 0,
      areaCode: '',
    });

    const loading = ref(false);
    const list = ref([]);
    const total = ref(0);

    const fetchList = async () => {
      loading.value = true;
      list.value = [];
      try {
        const { data } = await getSpaceListWithPic({
          ...query,
        });
        list.value = data.rows.map((item) => {
          const newItem = { ...item };
          if (newItem.tags?.length > 3) {
            newItem.tags.length = 3;
            newItem.tags.push('...');
          }
          return newItem;
        });
        total.value = data.count;
      } catch (e) {
        console.log(e);
      }
      loading.value = false;
    };

    // fetchList();

    window.onresize = () => {
      query.pageSize = getPageSizeByScreen();
      debounce(fetchList(), 1500);
    };

    // 每页条数改变
    const handlePageSizeChange = (pageSize) => {
      query.pageSize = pageSize;
      fetchList();
    };

    // 页数切换
    const handlePageChange = (pageNum) => {
      query.pageNo = pageNum;
      fetchList();
    };

    const handleQueryChange = (key, value) => {
      query[key] = value;
      fetchList();
    };

    const sortInfo = computed(() => {
      const sortType = query.sortType === 1 ? '升序' : '降序';
      const sortField = query.sortField === 1 ? '创建时间' : '更新时间';
      return `${sortField}${sortType}`;
    });

    const handleSort = (field, type) => {
      query.sortField = field;
      query.sortType = type;
      fetchList();
    };

    const hoverAreaName = ref('');
    const handleHoverArea = (areaName) => {
      hoverAreaName.value = areaName;
    };

    const handleLogout = () => {
      logout()
        .then(() => {
          window.location.href = '/login';
        })
        .catch(() => {
          ElMessage.error('登出失败');
        });
    };

    const areasCodes = ref([]);
    const getAreas = async () => {
      const { data } = await getAreaCodes(1);
      areasCodes.value = data;
    };

    const initBegin = ref(false);

    const inits = async () => {
      initBegin.value = false;
      if (token) {
        try {
          localStorage.setItem('projectId', '52');
          const tenantId = 8;
          const res = await getToken2Session(token, tenantId);
          if (res?.code === 0) {
            await getUser();
            fetchList();
            getAreas();
            initBegin.value = true;
          }
        } catch (e) {
          console.log(e);
        }
      } else {
        await getUser();
        initBegin.value = true;
        fetchList();
        getAreas();
      }
    };
    inits();

    const downloadDialogRef = ref(null);
    const openTable2Excel = () => {
      downloadDialogRef.value.toggleDialog();
    };

    const resolveCodes = ref([]);
    const areaList = ref([]);
    const getRootCodes = async (targetCode = '') => {
      if (targetCode.length === 9) {
        // eslint-disable-next-line no-param-reassign
        targetCode = targetCode.slice(0, 6);
      }
      const { data } = await getRootAreaCode();
      const [provence, city, area] = await Promise.all([
        getNextAreaCode(data.areaCode),
        getNextAreaCode(targetCode.slice(0, 2)),
        getNextAreaCode(targetCode.slice(0, 4)),
      ]);
      resolveCodes.value = {
        provence: provence.data,
        city: city.data,
        area: area.data,
      };
      areaList.value = {
        provence: targetCode.slice(0, 2),
        city: targetCode.slice(0, 4),
        area: targetCode,
      };
    };
    getRootCodes();
    watch(
      () => areaList.value.provence,
      async (nn) => {
        const { data } = await getNextAreaCode(nn);
        resolveCodes.value.city = data;
        areaList.value.city = data[0].areaCode;
      },
    );

    watch(
      () => areaList.value.city,
      async (nn) => {
        const { data } = await getNextAreaCode(nn);
        resolveCodes.value.area = data;
        // areaList.value.area = data[0].areaCode;
      },
    );
    const changeCity = () => {
      areaList.value.area = '';
    };
    const handleAreaSort = (areaCode, areaName) => {
      query.areaCode = areaCode;
      hoverAreaName.value = areaName;
      fetchList();
    };
    const handleAreaSortSelect = () => {
      query.areaCode = areaList.value.area;
      fetchList();
    };

    return {
      NoImage,
      LogoImg,
      LogoTxt,
      userInfo,
      query,
      loading,
      list,
      total,
      listType,
      collapse,
      sortInfo,
      fetchList,
      handleChangeListType,
      handlePageSizeChange,
      handlePageChange,
      handleQueryChange,
      handleSort,
      handleLogout,
      movPath,
      areasCodes,
      handleAreaSort,
      handleHoverArea,
      hoverAreaName,
      initBegin,
      inits,
      openTable2Excel,
      downloadDialogRef,
      resolveCodes,
      areaList,
      changeCity,
      handleAreaSortSelect,
    };
  },
};
</script>

<style lang="scss" scoped>
:deep(.el-loading-mask) {
  background: transparent;
}
.src {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  background: linear-gradient(135deg, #002b52 0%, #101217 80%);
  color: #979da6;
  overflow-x: hidden;
  overflow-y: auto;

  a {
    color: #237bfb;
  }

  &-header {
    margin: 20px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #237bfb;
    transform: translateZ(0);
    z-index: 10;
    position: relative;
    a {
      color: inherit;
    }
  }

  .dropdown {
    position: relative;
    .dropdown-toggle {
      cursor: pointer;
    }
    .dropdown-menu {
      display: none;
      position: absolute;
      width: 100%;
      background: linear-gradient(135deg, #002b52 0%, #101217 80%);
      color: #979da6;
      top: 100%;
      left: -2px;
      margin: 0;
      padding: 0;
      list-style: none;
      border: 1px solid #0059b5;
      z-index: 10;
      box-shadow: 0 0 3px rgba($color: #237bfb, $alpha: 0.7);
      a {
        display: flex;
        padding: 5px 10px;
        height: 40px;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: #979da6;
        transition: all 0.2s ease-in-out;
        &:hover {
          background-color: #237bfb;
          color: white;
        }
      }
    }
    &:hover {
      .dropdown-menu {
        display: block;
      }
    }
  }
  .skew-link {
    height: 48px;
    padding: 10px 40px;
    display: flex;
    align-items: center;
    background-color: rgba($color: #000000, $alpha: 0.1);
    font-size: 16px;
    border: 1px solid #0059b5;
    transform: skewX(-10deg) translateZ(0);
    position: relative;
    transition: all 0.2s ease-in-out;
    color: #237bfb;
    > span {
      transform: skewX(10deg);
    }
    &:hover {
      z-index: 1;
      color: white;
    }
    &:focus,
    &:active,
    &.is-active {
      box-shadow: 0 0 10px rgba($color: #237bfb, $alpha: 0.7);
      z-index: 1;
    }
    &.is-active {
      background-color: rgba($color: #0059b5, $alpha: 0.7);
      border-color: #237bfb;
      color: #fff;
      z-index: 1;
    }
    &--lr {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }
    &--rr {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
    &--r {
      border-radius: 3px;
    }
  }
  &-logo {
    display: inline-flex;
    align-items: center;
    :deep(.el-divider) {
      margin: 0 16px;
    }
    &__txt {
      position: relative;
      top: 1px;
    }
  }
  &-nav {
    display: inline-flex;
    margin: 0;
    padding: 0;
    list-style: none;
    align-items: center;
    justify-content: center;
    > li {
      position: relative;
      margin-left: -1px;
      > .skew-link {
        &.is-active {
          background-color: rgba(0, 0, 0, 0.4) !important;
          color: #237bfb;
          font-weight: bold;
        }
      }
    }
  }
  &-user {
    display: inline-flex;
    margin: 0;
    align-items: center;
    justify-content: center;
    .user-nav__icon {
      font-size: 20px;
    }
    &__avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 200px;
      height: 48px;
      border-radius: 48%;
      background-color: rgba($color: #000000, $alpha: 0.1);
      border: 1px solid transparent;
      font-size: 24px;
      text-align: center;
      i {
        width: 48px;
        height: 48px;
        line-height: 48px;
        border-radius: 48%;
        border: 1px solid #0059b5;
        position: relative;
        left: 40px;
      }
      .dropdown-menu {
        width: 200px !important;
        //margin-top: 10px !important;
      }
    }
    &__nick {
      margin-left: 15px;
      strong {
        font-size: 14px;
        font-weight: bold;
      }
    }
    &__logout {
      margin-left: 30px;
      padding: 10px 12px !important;
      width: 48px;
      height: 48px;
      text-align: center;
    }
  }

  &-banner {
    margin: 20px 40px;
    height: 340px;
    overflow: hidden;
    position: relative;
    border-radius: 10px;
    transform: translateZ(0);

    &::before {
      height: 100%;
      position: absolute;
      background: rgba(#13294b, 0.5);
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      content: '';
      z-index: 1;
    }
    video {
      width: 100%;
      transform: translateY(-50%) translateZ(0);
      position: relative;
      top: 50%;
    }
    &__body {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .search-wrap {
      width: 100%;
      max-width: 760px;
      background-color: rgba($color: #000000, $alpha: 0.2);
      border: 1px solid #0059b5;
      transform: skewX(-10deg);
      padding: 5px 30px;
      transition: all 0.2s ease-in-out;
      border-radius: 3px;
      font-size: 20px;
      .icon {
        position: absolute;
        transform: skewX(10deg);
        font-size: 24px;
        left: 24px;
        top: 24px;
        color: #237bfb;
      }
      &:focus-within {
        background-color: rgba($color: #000000, $alpha: 0.8);
        border-color: #237bfb;
        font-size: 24px;
        box-shadow: 0 0 10px rgba($color: #237bfb, $alpha: 0.7);
      }
      .search-input {
        height: 60px;
        width: 100%;
        display: block;
        transform: skewX(10deg);
        background-color: transparent;
        border: none;
        outline: none;
        color: #237bfb;
        font-size: inherit;
        font-weight: bold;
        padding: 0 40px;
      }
    }

    .btn-wrap {
      position: absolute;
      right: 20px;
      bottom: 20px;
      display: flex;
      align-items: center;
      .skew-link {
        height: 40px;
        padding: 5px 25px;
        background-color: rgba($color: #0059b5, $alpha: 0.7);
        border-color: #237bfb;
        color: white;
        + .skew-link {
          margin-left: -1px;
        }
      }
    }
  }

  &-main {
    margin: 20px 40px;
    display: flex;
    align-items: flex-start;
    transform: translateZ(0);
    &__list {
      flex: 1;
      min-width: 700px;
      overflow-x: hidden;
      &_filter {
        padding: 10px;
        background-color: rgba($color: #000000, $alpha: 0.2);
        border-radius: 5px;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
      }
      .filter-r {
        margin-left: auto;
        display: flex;
        align-items: center;
      }
      .list-types {
        display: flex;
        align-items: center;
        .skew-link {
          width: 40px;
          height: 40px;
          padding: 2px 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 20px;
          + .skew-link {
            margin-left: -1px;
          }
          span {
            position: relative;
            top: 2px;
            left: 1px;
          }
        }
      }
      :deep(.sa-list-wrap__content) {
        overflow: visible;
      }
      .list-sort {
        margin-left: 10px;
        .skew-link {
          height: 40px;
        }
      }
      .list-areas {
        margin-right: 10px;
        .el-icon-arrow-up {
          display: none;
        }
        .el-icon-arrow-down {
          display: inline;
        }
        &:hover {
          width: 400px;
          .dropdown-menu {
            display: flex !important;
          }
          .el-icon-arrow-up {
            display: inline;
          }
          .el-icon-arrow-down {
            display: none;
          }
        }
        .skew-link {
          height: 40px;
        }
        .dropdown-menu {
          display: none;
          width: 400px;
          flex-flow: row wrap;
          align-content: flex-start;
          li {
            flex: 0 0 25%;
            height: 50px;
            box-sizing: border-box;
          }
        }
      }
    }
    &__aside {
      flex-shrink: 0;
      margin-left: 20px;
      width: 400px;
      background-color: rgba($color: #000000, $alpha: 0.2);
      border-radius: 5px;
      :deep(.el-collapse-item__header),
      :deep(.el-collapse-item__wrap),
      :deep(.el-tree) {
        background: transparent;
        color: #5f759b;
      }
      :deep(.el-collapse),
      :deep(.el-collapse-item__wrap),
      :deep(.el-collapse-item__header) {
        border-top-color: #39455d;
        border-bottom-color: #39455d;
      }
      :deep(.el-collapse-item__header),
      :deep(.el-collapse-item__wrap) {
        padding: 0 20px;
        color: #5f759b;
      }
      :deep(.el-collapse-item__content) {
        padding-top: 20px;
      }
      :deep(.el-collapse-item__header) {
        font-size: 14px;
        padding-right: 10px;
        color: #979da6;
        i {
          font-size: 16px;
          margin-right: 5px;
        }
      }
      :deep(.el-tree) {
        .el-tree-node__content:hover {
          background-color: rgba($color: #000000, $alpha: 0.5);
        }
      }
      :deep(.el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content) {
        background-color: rgba(0, 89, 181, 0.7);
        color: white;
      }
      :deep(.clear) {
        color: #5f759b;
      }
    }
  }
  :deep(.el-tag) {
    border-radius: 3px;
    background-color: rgba($color: #000000, $alpha: 0.1);
    border: 1px solid #39455d;
    color: #5f759b;
    &.is-active {
      background-color: rgba($color: #0059b5, $alpha: 0.7);
      border-color: #237bfb;
      color: white;
    }
  }

  .list-page {
    margin: 40px 0;
    text-align: center;

    :deep(.el-pagination) {
      display: inline-block;
      margin-left: 20px;
      vertical-align: middle;
      .el-icon {
        display: inline-block;
      }
      .btn-next,
      .btn-prev,
      li {
        background-color: rgba($color: #000000, $alpha: 0.1);
        border: 1px solid #0059b5;
        color: #979da6;
      }
      li {
        font-weight: normal;
        &:not(.active):hover {
          color: #237bfb;
        }
        &.active {
          background-color: #237bfb;
          border-color: #237bfb;
        }
      }
    }
  }
}
</style>
