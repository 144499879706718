<template>
  <el-dialog title="导出预览" v-model="visible">
    <el-table :data="tableData" height="450" ref="excelTableRef">
      <el-table-column type="index" width="50" />
      <el-table-column prop="serviceName" label="服务名称"></el-table-column>
      <el-table-column prop="serviceTypeName" label="服务类型"></el-table-column>
      <el-table-column prop="createTime" label="发布时间"></el-table-column>
      <el-table-column prop="updateTime" label="更新时间"></el-table-column>
      <el-table-column prop="description" label="服务描述"></el-table-column>
    </el-table>
    <template #footer>
      <div>
        <el-button @click="toggleDialog">取消</el-button>
        <el-button type="primary" @click="getExcel">下载Excel</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { ref, defineComponent, watch } from 'vue';
import { getSpaceServiceList, getSpaceServiceExcel } from '@/api/cim';
import * as XLSX from 'xlsx';
import { openDownloadDialog, sheet2blob } from './util';

export default defineComponent({
  name: 'DownloadDialog',
  setup() {
    const visible = ref(false);
    const toggleDialog = () => {
      visible.value = !visible.value;
    };
    const tableData = ref([]);
    const payload = {
      keyword: '',
      catalogId: null,
      tagNames: [],
      pageNo: 1,
      pageSize: 20,
      sortField: 1,
      sortType: 0,
    };
    const fetchList = async () => {
      const { data } = await getSpaceServiceList(payload);
      tableData.value = [...tableData.value, ...data.list];
    };

    const excelTableRef = ref(null);

    const lazyLoad = () => {
      const scrollBody = document.getElementsByClassName('el-table__body-wrapper')[0];
      scrollBody.addEventListener('scroll', (e) => {
        const { target } = e;
        const distance = target.scrollHeight - target.scrollTop - target.clientHeight;
        if (distance <= 0) {
          payload.pageNo += 1;
          fetchList();
        }
      });
    };
    watch(excelTableRef, () => {
      lazyLoad();
    });

    fetchList();

    const json2excel = (list) => {
      console.log(XLSX.utils);
      const header = ['serialNo', 'serviceName', 'serviceTypeName', 'createTime', 'updateTime', 'description'];
      const headerData = {
        serialNo: '序号',
        serviceName: '服务名称',
        serviceTypeName: '服务类型',
        createTime: '发布时间',
        updateTime: '更新时间',
        description: '服务描述',
      };
      const sheet = XLSX.utils.json_to_sheet([headerData, ...list], { header, skipHeader: true });
      openDownloadDialog(sheet2blob(sheet), '空间服务汇总.xlsx');
      console.log(sheet);
    };
    const getExcel = async () => {
      const payload = {
        keyword: '',
        catalogId: null,
        tagNames: [],
        sortField: 1,
        sortType: 0,
        areaCode: '',
      };
      const { data } = await getSpaceServiceExcel(payload);
      json2excel(data);
    };

    return {
      visible,
      toggleDialog,
      tableData,
      excelTableRef,
      getExcel,
    };
  },
});
</script>
